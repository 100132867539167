<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>Dashboard</strong>
      </CCardHeader>
      <CCardBody>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>


export default {
  name: "Index",
  components: {

  },
  data() {
    return {
      user: [],
      copiedMsg: 0
    };
  },
  mounted: function() {
    this.onLoadPage();
  },
  methods: {
    onLoadPage: function() {

    },
  }
};
</script>
